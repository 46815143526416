import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import helpers from "./helpers";
import endpoint from "./services/api.module";
import moment from "moment";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { GoogleLoginPlugin } from 'vue3-google-login';

const app = createApp(App).use(store).use(moment).use(router).use(ElementPlus).use(CKEditor);
app.config.globalProperties.$helpers = helpers;
app.config.globalProperties.$endpoint = endpoint;
app.config.globalProperties.$moment = moment;
app.use(GoogleLoginPlugin, {
    clientId: "291259022619-sjr5qtdkbu14tubta3m6mlka5hbg1ddv.apps.googleusercontent.com",
  });
app.mount("#app");
