import AuthService from "../services/auth.service";
import axios from "axios";

const user = JSON.parse(localStorage.getItem("user"));
const token = localStorage.getItem("auth_token") || "";

const initialState = user
  ? {
      status: { loggedIn: true },
      user,
      token,
    }
  : {
      status: { loggedIn: false },
      user: null,
    };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    getAccessLogs({ commit }, data) {
      return AuthService.getAccessLogs(data).then((data) => {
        commit("access_logs", data);
        return Promise.resolve(data);
      });
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
      localStorage.removeItem("auth_token");
      delete axios.defaults.headers.common["Authorization"];
    },
    setToken({ commit }, token) { 
      localStorage.setItem("auth_token", token);
      commit("setAuthToken", token);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    access_logs(state, data) {
      state.access_logs = data;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    setAuthToken(state, token) { 
      state.token = token;
    },
  },
};
